<template>
 <div id="app">
  <!-- <router-link to="/">Home</router-link> -->
  
  <router-view />

</div>

</template>

<script>
export default {
  name: "app", 
  template: '<div>{{ jarm_result }}</div>'
};
</script>